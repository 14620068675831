<template>
  <div class="app-container">
    <div class="page-title">
      <h2>La catégorie</h2>
    </div>

    <!-- <el-card class="box-card"> -->
    <div class="filter-container">
      <!-- <el-button style="margin-left: auto" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="PrintProductor">
        Imprimer
      </el-button> -->
      <el-button v-if="checkPermission(['creer question'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateQuestion">
        {{ $t('general.add') }}
      </el-button>
    </div>
    <el-row>
      <el-col :span="7" style="margin-right:7px">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight:bold">Informations de la catégorie</span>
              <!-- <el-button style="float: right; padding: 3px 0" type="text">Bouton</el-button> -->
            </div>
            <div class="text item" style="display: flex; align-items: center; flex-direction:column">
              <!-- <div class="pan-thumb" />
                <span style="font-weight: bold; font-style: italic; margin-bottom: 5px">{{ data.nom + ' ' + data.prenom }}</span>
                <span style="font-style: italic;">{{ data.sexe ==='f'?'Féminin':'Masculin' | uppercaseFirst }}</span> -->
              <el-table v-loading="loading" :data="persinfos" :show-header="false">
                <el-table-column>
                  <template slot-scope="scope" style="background-color: bisque">
                    <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                  </template>
                </el-table-column>
                <el-table-column>
                  <template slot-scope="scope">
                    <span style="font-style: italic;">{{ scope.row.valeur }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="grid-content bg-purple-light">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight: bold;">Détails</span>
            </div>
            <el-tabs tab-position="top">
              <!-- <el-tab-pane label="Organisation">
                  <el-table v-loading="loading" :data="organInfos" :show-header="false">
                    <el-table-column>
                      <template slot-scope="scope" style="background-color: bisque">
                        <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template slot-scope="scope">
                        <span style="font-style: italic;">{{ scope.row.valeur }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane> -->
              <el-tab-pane label="Questions">
                <el-table :data="data.questions" border fit highlight-current-row style="width: 100%">
                  <!-- <el-table-column align="center" label="Code" min-width="100px">
                    <template slot-scope="scope">
                      <el-link>
                        <span>{{ scope.row.code }}</span>
                      </el-link>
                    </template>
                  </el-table-column> -->

                  <el-table-column align="center" :label="$t('question.libelle')[0].toUpperCase()+$t('question.libelle').slice(1)" min-width="250px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.libelle }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" :label="$t('general.reference')" min-width="160px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.reference }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column v-if="checkPermission(['activer question'])" align="center" :label="$t('general.status')" width="100">
                    <template slot-scope="scope">
                      <el-switch
                        style="display: block"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        :active-value="true"
                        :inactive-value="false"
                        :value="scope.row.is_active"
                        @change="setQuestionStatus(scope.row.id,'active')"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column v-if="checkPermission(['modifier question','visible question','supprimer question'])" align="center" :label="1===1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
                    <template slot-scope="scope">
                      <el-button v-if="scope.row.is_visible && checkPermission(['modifier question'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditQuestion(scope.row.id);" />
                      <el-button v-if="checkPermission(['visible question','supprimer question'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteQuestion(scope.row.id, scope.row.is_visible, scope.row.libelle);" />
                    </template>
                  </el-table-column>

                  <!-- <el-table-column align="center" :label="$t('producteur.numero')" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.numero }}</span>
                    </template>
                  </el-table-column> -->

                  <!-- <el-table-column align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
                      <template slot-scope="scope">
                        <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateProducteur(scope.row.id)" />
                      </template>
                    </el-table-column> -->
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <!-- <el-table v-loading="loading" :data="mininfos" :show-header="false">
        <el-table-column>
          <template slot-scope="scope" style="background-color: bisque">
            <span style="font-weight: bold;">{{ scope.row.name1 }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            {{ scope.row.val1 }}
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <span style="font-weight: bold">{{ scope.row.name2 }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            {{ scope.row.val2 }}
          </template>
        </el-table-column>
      </el-table> -->
    <!-- <table v-if="data !== null" style="border : 1px solid #000000; width:100%" cellspacing="0">
        <tr>
          <td style="border: 1px solid #000;">Code UP</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.code }}</td>
          <td style="border: 1px solid #000;">Nom du producteur</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.nom + ' ' + data.prenom }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Zone</td>
          <td style="border: 1px solid #000; width: 200px;">{{ }}</td>
          <td style="border: 1px solid #000;">Sexe (M/F)</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.sexe | uppercaseFirst }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Commune</td>
          <td style="border: 1px solid #000; width: 200px;">{{ }}</td>
          <td style="border: 1px solid #000;">Groupement</td>
          <td style="border: 1px solid #000; width: 200px;">{{ nomGroupements[data.groupement_id] }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Village</td>
          <td style="border: 1px solid #000; width: 200px;">{{ nomVillages[data.village_id] }}</td>
          <td style="border: 1px solid #000;">Code Producteur</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.code }}</td>
        </tr>
      </table> -->

    <!-- </el-card> -->
    <!-- <h4>Liste des Parcelles du producteur</h4>
    <el-card class="box-card filter-card just-for-admin">
      <el-table :data="parcelles" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="Code" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('parcelle.superficie')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.superficie }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('parcelle.nbr_arb')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.nbr_arb }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template>
            <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateProducteur" />
          </template>
        </el-table-column>
      </el-table>
    </el-card> -->
    <el-dialog
      :title="editing ? $t('question.edit') + ' ' + currentQuestion.libelle : $t('question.AddQuestion')"
      :visible.sync="dialogCreateQuestion"
      :before-close="handleCloseCampagneagricoleCreateDraw"
      :loading="questionCreating"
      width="30%"
    >
      <div class="content-inputs">
        <!-- <el-radio-group v-model="ciQuestion" size="medium">
          <el-radio-button :label="'audit'">Audit</el-radio-button>
          <el-radio-button :label="'ci'">Contrôle interne</el-radio-button>
        </el-radio-group> -->
        <el-form ref="questionForm" :rules="rules" :model="currentQuestion" label-position="left">
          <!-- <div class="ligne">
            <el-form-item :label="$t('categorieQuestion.categorieQuestion')" prop="categorie_question_id">
              <el-select v-model="currentQuestion.categorie_question_id" class="filter-item" :placeholder="$t('categorieQuestion.categorieQuestion')" style="width: 100%;" prop="categorie_question_id">
                <el-option
                  v-for="(categorieQuestion, idex) in categoriesFiltrees"
                  :key="idex"
                  :label="categorieQuestion.libelle | uppercaseFirst"
                  :value="categorieQuestion.id"
                  :disabled="!categorieQuestion.is_active"
                />
              </el-select>
            </el-form-item>
            <el-button class="bouton" type="primary" :icon="dialogCreateCategorieQuestion ? 'el-icon-minus' : 'el-icon-plus'" circle @click="dialogCreateCategorieQuestion=!dialogCreateCategorieQuestion" />
          </div> -->
          <el-form-item :label="$t('general.libelle')" prop="libelle">
            <el-input v-model="currentQuestion.libelle" />
          </el-form-item>
          <el-form-item :label="$t('general.reference')" prop="reference">
            <el-input v-model="currentQuestion.reference" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="text-align:right;">
          <el-button plain type="danger" @click="cancelquestionForm()">
            {{ $t('permission.cancel') }}
          </el-button>
          <el-button type="primary" :loading="questionCreating" @click="editing ? updateQuestion() : createQuestion()">
            {{ questionCreating ? $t('general.saving') : $t('general.save') }}
          </el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
// const controleurResource = new Resource('controleurs');
const groupementResource = new Resource('groupements');
const categorieQuestionResource = new Resource('categorie_questions');
const villageResource = new Resource('villages');
const questionResource = new Resource('questions');
export default {
  name: 'ProducteurInfo',
  // components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      data: null,
      date: '',
      ciQuestion: '',
      dialogCreateQuestion: false,
      editing: false,
      questionCreating: false,
      fullscreenLoading: true,
      parcelles: null,
      printing: false,
      list: null,
      total: 0,
      sexe: '',
      image: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      nomVillages: null,
      nomGroupements: null,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
      },
      loading: true,
      downloading: false,
      villages: null,
      groupements: null,
      activeList: 'disponibles',
      producteurCreating: false,
      persinfos: null,
      organInfos: null,
      currentProducteur: {},
      currentQuestion: {
        categorie_question_id: this.$route.params.id,
        libelle: '',
        reference: '',
      },
      dialogCreateProducteur: false,
      currentProducteurId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('producteur.NomRequired'), trigger: 'blur' }],
        prenom: [{ required: true, message: this.$t('producteur.PrenomRequired'), trigger: 'blur' }],
        numero: [{ required: true, message: this.$t('producteur.NumeroRequired'), trigger: 'blur' }],
        adresse: [{ required: true, message: this.$t('producteur.AdresseRequired'), trigger: 'blur' }],
      },
      fileList: [],
      mininfos: [],
    };
  },
  computed: {

  },
  created() {
    this.getGroupementsList();
    this.getVillagesList();
    this.getCategorieQuestion();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    cancelquestionForm() {
      this.dialogCreateQuestion = false;
      this.getCategorieQuestion();
    },
    handleEditQuestion(id){
      this.editing = true;
      this.resetNewQuestion();
      this.currentQuestion = this.data.questions.find(abonne => abonne.id === id);
      this.dialogCreateQuestion = true;
      this.$nextTick(() => {
        this.$refs['questionForm'].clearValidate();
      });
    },
    handleDeleteQuestion(id, visible, libelle) {
      console.log('id : ' + id + 'libelle : ' + libelle);
      this.$confirm(this.$t('question.deleteWarn') + ' ' + libelle + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer question']))) {
          console.log('result 2 : ');
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              questionResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('question.suppressionEffectue'),
                });
                this.getCategorieQuestion();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setQuestionStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    async setQuestionStatus(id, type){
      const data = await questionResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.getCategorieQuestion();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleFilterQuestions() {
      this.query.page = 1;
      this.getCategorieQuestion();
    },
    async getGroupementsList() {
      const { data } = await groupementResource.list(this.query);
      this.groupements = data;
      var _reg = {};
      data.forEach(groupement => {
        _reg[groupement.id] = groupement.nom;
      });
      this.nomGroupements = _reg;
    },
    PrintProductor(){
      var routeData = this.$router.resolve({ path: '/producteur/' + this.$route.params.id });
      window.open(routeData.href, '_blank');
    },
    navigateImpression(){
      var routeData = this.$router.resolve({ path: '/parcelle/' + this.$route.params.id });
      window.open(routeData.href, '_blank');
    },
    navigateProducteur(id){
      this.$router.push({ path: '/producteurs/' + id });
    },
    handlecreateQuestion() {
      this.editing = false;
      this.resetNewQuestion();
      this.dialogCreateQuestion = true;
      this.$nextTick(() => {
        this.$refs['questionForm'].clearValidate();
      });
    },
    createQuestion() {
      this.$refs['questionForm'].validate((valid) => {
        if (valid) {
          this.questionCreating = true;
          questionResource
            .store(this.currentQuestion)
            .then(async(response) => {
              this.$message({
                message: this.$t('question.NewQuestion') + ' ' + this.currentQuestion.libelle + ' ' + this.$t('question.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentQuestionId = response.data.id;
              this.resetNewQuestion();
              this.dialogCreateQuestion = false;
              this.handleFilterQuestions();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.questionCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    updateQuestion() {
      this.$refs['questionForm'].validate((valid) => {
        if (valid) {
          this.questionCreating = true;
          questionResource.update(this.currentQuestion.id, this.currentQuestion).then(response => {
            this.resetNewQuestion();
            this.handleFilterQuestions();
            this.$message({
              message: this.$t('question.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateQuestion = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.questionCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewQuestion() {
      this.currentQuestion = {
        categorie_question_id: this.$route.params.id,
        libelle: '',
      };
    },
    async getVillagesList() {
      const { data } = await villageResource.list(this.query);
      this.villages = data;
      var _reg = {};
      data.forEach(village => {
        _reg[village.id] = village.nom;
      });
      this.nomVillages = _reg;
    },
    async getCategorieQuestion() {
      // const { data } = await producteursResource.get(this.$route.params.id);
      const { data } = await categorieQuestionResource.get(this.$route.params.id);
      this.data = data;
      console.log('leproduitttttttttt : ', this.data);
      this.fullscreenLoading = false;
      console.log('le loading : ', this.fullscreenLoading);
      this.date = moment().format('DD-MM-YYYY');
      // this.organInfos = [
      //   {
      //     'libelle': 'Code UP    :',
      //     'valeur': data.unite_production !== null ? data.unite_production.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Zone    :',
      //     'valeur': data.zone !== null ? data.zone.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Commune    :',
      //     'valeur': data.commune !== null ? data.commune.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Groupement',
      //     'valeur': data.groupement !== null ? data.groupement.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Village',
      //     'valeur': data.village !== null ? data.village.nom : 'INDISPONIBLE',
      //   },
      // ];
      this.persinfos = [
        {
          'libelle': 'Nom    :',
          'valeur': data.libelle,
        },
        {
          'libelle': 'Description    :',
          'valeur': data.description,
        },
      ];
      // this.mininfos = [
      //   {
      //     'name1': 'Code UP',
      //     'val1': data.unite_production !== null ? data.unite_production.nom : 'INDISPONIBLE',
      //     'name2': 'Nom du producteur',
      //     'val2': data.nom + ' ' + data.prenom,
      //   },
      //   {
      //     'name1': 'Zone',
      //     'val1': data.zone !== null ? data.zone.nom : 'INDISPONIBLE',
      //     'name2': 'Sexe (M/F)',
      //     'val2': data.sexe,
      //   },
      //   {
      //     'name1': 'Commune',
      //     'val1': data.commune !== null ? data.commune.nom : 'INDISPONIBLE',
      //     'name2': 'Groupement',
      //     'val2': data.groupement !== null ? data.groupement.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'name1': 'Village',
      //     'val1': data.village !== null ? data.village.nom : 'INDISPONIBLE',
      //     'name2': 'Code Producteur',
      //     'val2': data.code,
      //   },
      // ];
      this.loading = false;
      // console.log('tableau : ', this.mininfos);
      // if (data !== null) {
      //   window.print();
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.pan-thumb {
  width: 150px;
  height: 150px;
  background-position: center center;
  background-size: cover;
  border-radius: 75px;
  overflow: hidden;
  margin-bottom: 20px;
  // position: absolute;
  background-image:url('https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg');
  // transform-origin: 95px 40px;
  // transition: all 0.3s ease-in-out;
  background-repeat:no-repeat;
}
</style>
